@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Inter";
    font-weight: 100 1000;
    src: url(./fonts/InterVariable.woff2) format("woff2");
    font-display: swap;
  }
}
